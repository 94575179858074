import { Controller } from "@hotwired/stimulus"
import debounce from "lodash/debounce"
import find from "lodash/find"
import { turboStreamFetch } from "src/utils/http"

export default class extends Controller {
  static values = {'getTotalUrl': String}
  static targets = ['price', 'package', 'flatrates']

  get form() {
    return this.element.closest("form")
  }

  get billingOptions() {
    return this.form?.querySelectorAll(".billing-options input") || []
  }

  connect() {
    this.element.addEventListener("conversion:items-changed", this.updateTotals)
    for (const billingOption of this.billingOptions) {
      billingOption.addEventListener("change", this.billingOptionChanged)
    }

    this.showOrHidePrices()
  }

  disconnect() {
    this.element.removeEventListener("conversion:items-changed", this.updateTotals)
    for (const billingOption of this.billingOptions) {
      billingOption.removeEventListener("change", this.billingOptionChanged)
    }
  }

  billingOptionChanged = () => {
    this.showOrHidePrices()
  }

  showOrHidePrices = () => {
    const contractPeriod = +this.contractPeriod

    this.priceTargets.forEach((t) => {
      t.classList.toggle("hidden", contractPeriod !== +t.dataset.contractPeriodInMonths)
    })

    this.updateTotals()
  }

  get contractPeriod() {
    return find(this.billingOptions, (o) => o.checked)?.value
  }

  get packageCount() {
    return this.packageTargets.reduce((sum, input) => sum += parseInt(input.value), 0)
  }

  updateTotals = debounce(async () => {
    if (this.hasFlatratesTarget) {
      this.flatratesTarget.innerText = this.packageCount
    }

    await turboStreamFetch(this.getTotalUrlValue, {
      method: "POST",
      body: new FormData(this.form),
    })
  }, 300)
}
