import { Controller } from '@hotwired/stimulus'

function nearestToggleGroup(input) {
  return input.closest('[data-controller*=toggle-group]')
}

export default class extends Controller {
  static targets = ['toggle']
  static values = {
    preventUpdatesInNestedToggleGroups: { default: true, type: Boolean },
    dispatchChangeEventForVisibleFormElements: { default: false, type: Boolean }
  }

  connect() {
    document.querySelectorAll('\
      select[data-toggle-group],\
      input[data-toggle-group][type="hidden"],\
      input[data-toggle-group][checked="checked"]'
    ).forEach(el => {
      el.dispatchEvent(new Event('input'))
      el.dispatchEvent(new Event('change'))
    })
  }

  toggle(event) {
    const toggleGroup = event.target.dataset.toggleGroup
    let value = event.target.value || event.target.dataset.value || ""

    if (event.target.type == 'checkbox' && !event.target.checked) {
      value = null
    }

    this.toggleTargets.filter(input => (input.dataset.toggleGroup === toggleGroup)).forEach((el, i) => {
      if (
        (el.dataset.if !== undefined && el.dataset.if.split(',').includes(value)) ||
        (el.dataset.unless !== undefined && !el.dataset.unless.split(',').includes(value))
      ) {
        el.classList.remove('hidden')
        for (const input of el.querySelectorAll('input, select, textarea')) {
          if (this.preventUpdatesInNestedToggleGroupsValue && nearestToggleGroup(input) !== this.element) continue

          input.disabled = false

          if (input.selectize) {
            input.selectize.enable()
          }

          if (this.dispatchChangeEventForVisibleFormElementsValue) {
            input.dispatchEvent(new Event('input'))
            input.dispatchEvent(new Event('change'))
          }
        }
      } else {
        el.classList.add('hidden')
        for (const input of el.querySelectorAll('input, select, textarea')) {
          if (this.preventUpdatesInNestedToggleGroupsValue && nearestToggleGroup(input) !== this.element) continue

          input.disabled = true

          if (input.selectize) {
            input.selectize.disable()
          }
        }
      }
    })

    if (event.target.dataset.hideOnToggle === 'true') {
      event.target.classList.add('hidden')
    }
  }
}
