import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["animationData", "container"]

  async connect() {
    const lottieModule = import(/* webpackChunkName lottie */ 'lottie-web')
    const lottie = (await lottieModule).default

    lottie.loadAnimation({
      container: this.containerTarget,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: JSON.parse(this.animationDataTarget.innerHTML),
    });
   }
}
